/** Styles */
import '@/assets/scss/main.scss'
import '@mdi/font/css/materialdesignicons.css'

/**  Core
 * */
import Vue from 'vue'
import Buefy from 'buefy'
import VueCookies from 'vue-cookies'
import App from './App.vue'
import i18n from '@/icons/index'
import vueDebounce from 'vue-debounce'
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import SvgIcon from '@/components/icons/SvgIcon'
Vue.use(vueDebounce, {
    defaultTime: '300ms'
})
Vue.use(VueTelInput)

Vue.use(VueCookies);


Vue.use(Buefy,
    {
        defaultIconPack: null,
        defaultIconComponent: SvgIcon
    }
)

Vue.use(CKEditor);

import AsideMenuList from '@/components/layout/AsideMenuList'

Vue.component('AsideMenuList', AsideMenuList)

/** Router */
import router from './router'
/**
 * Router Guards
 */
import "@/router/guards/Auth";
import '@/router/guards/Terms';
import "@/router/guards/Role";
import '@/router/guards/Permission';
import '@/router/guards/MobileMenu'
import "@/router/guards/Loader";
import '@/router/guards/Title'
import '@/router/guards/SideMenuGuard'

/** Store */
import store from './store'
import handleError from "@/mixins/ResponseErrors";
import setQuery from "@/mixins/QueryManipulation";
import removeQuery from "@/mixins/QueryManipulation";
import './registerServiceWorker'
import makeModalLarge from "@/mixins/LargeModal";

Vue.$cookies.config(-1,'','',true)


/** Prototypes */
// Vue.prototype.$cookie = VueCookies;
Vue.mixin(handleError)
Vue.mixin(setQuery)
Vue.mixin(removeQuery)
Vue.mixin(makeModalLarge)


Vue.config.productionTip = false

new Vue({
    store,
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')
