import {TransformModel} from "@/models/TransformModel";
import User from "@/models/User";
import Subject from "@/models/Subject";

/**
 * A classGroup
 * @property {number} id
 * @property {string} name - The name of the classGroup
 * @property {number} subject_id - The id of the subject the class is associated with
 * @property {Array<number>} teachers_ids - The id of the teachers associated with this class
 * @property {number} campus_id - The id of the campus this class belongs to
 */
export default class ClassGroup extends TransformModel {
    static entity = 'classGroups'

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(''),
            subject_id:this.attr(null),
            subject:this.belongsTo(Subject,'subject_id'),
            teachers_ids: this.attr(() => []),
            teachers: this.hasManyBy(User, 'teachers_ids'),
            campus_id: this.attr(null),
            class_group_id:this.attr(null),
            is_global:this.attr(null)

        }
    }
    /**
     * Returns all classGroups
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {?Object} [query={}] - Query terms for the request
     * @param {?number} [query.campus_id]
     * @param {number} [query.subject_id]
     * @param {string} [query.search]
     * @param {string} mode - how to persist the response
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchAll({page = 1, limit = 15}, query = {}, relationships = [], mode = 'insertOrUpdate') {
        return this.api().get(`/class-groups`, {
                persistBy: mode,
                params: {
                    ...{
                        page: page, limit: limit, with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(classGroup => {
                        if (relationships.includes('teachers')) {
                            if (Object.prototype.hasOwnProperty.call(classGroup.attributes,'teachers')) {
                                classGroup.attributes.teachers.map(teacher => {
                                    Object.assign(teacher, teacher.attributes)
                                })
                            }
                        }
                        if (relationships.includes('subject')) {
                            Object.assign(classGroup.attributes.subject, classGroup.attributes.subject.attributes)
                        }
                        return {...classGroup, ...classGroup.attributes}
                    })
                },
            }
        )
    }

    /**
     * Returns a classGroup by its id
     * @function
     * @param {number} id The id of the classGroup
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchById(id, relationships = []) {
        return this.api().get(`/class-groups/${id}`, {
                params: {
                    with: relationships
                },
                dataTransformer: ({data: {data}}) => {
                    if (Object.prototype.hasOwnProperty.call(data.attributes, 'teachers')) {

                        if (relationships.includes('teachers')) {
                            data.attributes.teachers.map(teacher => {
                                Object.assign(teacher, teacher.attributes)
                            })
                        }
                    }
                    if (relationships.includes('subject')) {
                        Object.assign(data.attributes.subject, data.attributes.subject.attributes)
                    }
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Store a new classGroup
     * @function
     * @param {Object} classGroup - The class object
     * @param {string} classGroup.name - The name of the classs
     * @param {number} classGroup.campus_id - The id of the campus the class is associated with
     * @param {number} classGroup.subject_id - The id of the subject the class is associated with
     * @returns {Promise<Response>} - The newly created class
     */

    static Store(classGroup) {
        return this.api().post(`/class-groups`, classGroup, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Update an existing classGroup
     * @function
     * @param {Object} classGroup - The class object
     * @param {string} classGroup.name - The name of the classs
     * @param {number} classGroup.campus_id - The id of the campus the class is associated with
     * @param {number} classGroup.subject_id - The id of the subject the class is associated with
     * @returns {Promise<Response>} - The newly created class
     */

    static Update(classGroup) {
        return this.api().patch(`/class-groups/${classGroup.id}`, classGroup, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Delete an existing classGroup
     * @function
     * @param {number} classGroup_id - The id of the classGroup
     * @returns {Promise<Response>} -
     */

    static Delete(classGroup_id) {
        return this.api().delete(`/class-groups/${classGroup_id}`, {
                delete: classGroup_id
            }
        )
    }


}
