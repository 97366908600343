import {TransformModel} from "@/models/TransformModel";

/**
 * A support ticket
 * @property {string} description
 * @property {string} category
 * @property {string} page_url
 * @property {Object} date_time
 * @property {string} campus
 * @property {string} status
 * @property {number} user_id
 * @property {Object} media
 */
export default class SupportTicket extends TransformModel {
    static entity = 'supportTickets'

    static fields() {
        return {
            id: this.attr(null),
            category: this.attr(null),
            page_url: this.attr(null),
            date_time: this.attr(null),
            campus: this.attr(null),
            status: this.attr(null),
            user_id: this.attr(null),
            media: this.attr(null),
        }
    }

    static FetchAll({page = 1, limit = 999}, query = {}) {
        return this.api().get(`/tickets`, {
                params: {
                    ...{
                        page: page, limit: limit,
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(type => {
                        return {...type, ...type.attributes}
                    })
                },
            }
        )
    }

    static Store(ticket) {
        console.log("in SupportTicket.js");
        return this.api().post(`/tickets`, ticket, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    static Update(ticket, saved) {
        return this.api().patch(`/tickets/${ticket.id}`, ticket, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
                save: saved
            }
        )
    }

    static Delete(ticket_id) {
        return this.api().delete(`/tickets/${ticket_id}`, {
                delete: ticket_id
            }
        )
    }


}
