import {
    TransformModel
} from '@/models/TransformModel'


/**
 * A media item
 * @property {number} id
 */
export default class Media extends TransformModel {
    static entity = 'media'

    static fields() {
        return {
            type: this.attr('media'),
            id: this.attr(null),
            filename: this.attr(null),
            extension: this.attr(null),
            tag: this.attr(null),
            caption: this.attr(null),
            temporary_url: this.attr(null),
            url: this.attr(null),
            mediable_id: this.attr(null),
            mediable_type: this.attr(null),
            visibility: this.attr(null),
        }
    }

    /**
     * Returns all media
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {?Object} [query={}] - Query terms for the request
     * @param {string} [query.modelName] - type of the model - plural
     * @param {string} [query.model] - id of the model
     * @param {string} [query.tag] - Tag of the media
     * @returns {Promise<Response>}
     */

    static FetchAll({page = 1, limit = 15}, query = {}, relationships = []) {
        return this.api().get(`/media`, {
                params: {
                    ...{
                        page: page, limit: limit, with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(media => {
                        if (Object.keys(query).includes('modelName')) {
                            media.mediable_type = query['modelName']
                        }
                        if (Object.keys(query).includes('model')) {
                            media.mediable_id = query['model']
                        }
                        if (Object.keys(query).includes('tag')) {
                            media.mediable_tag = query['tag']
                        }
                        if (Object.keys(query).includes('visibility')) {
                            media.mediable_visibility = query['visibility']
                        }
                        // if (relationships.includes('user')) {
                        //     Object.assign(task.attributes.user, task.attributes.user.attributes)
                        // }
                        return {...media, ...media.attributes}
                    })
                },
            }
        )
    }

    /**
     * Returns a specific media
     * @function
     * @param {Number} id
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchById(id, relationships = []) {
        return this.api().get(`/media/${id}`, {
                params: {
                    ...{
                        with: relationships
                    },
                },
                dataTransformer: ({data: {data}}) => {

                    // if (relationships.includes('user')) {
                    //     Object.assign(task.attributes.user, task.attributes.user.attributes)
                    // }
                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Store a new media item
     * @function
     * @param {Object} model - the associated entity
     * @param {string} model.model - the associated entity type
     * @param {number} model.model_id - the associated entity id
     * @param {FormData} media
     * @returns {Promise<Response>} - The newly created user
     */

    static Store(model, media) {
        return this.api().post(`/${model.model}/${model.model_id}/media`, media, {
                dataTransformer: ({data: {data}}) => {

                    // if (relationships.includes('user')) {
                    //     Object.assign(task.attributes.user, task.attributes.user.attributes)
                    // }
                    data.mediable_type = model.model
                    data.mediable_id = model.model_id
                    return {...data, ...data.attributes}
                },
            }
        )
    }
    /**
     * Update a new media item
     * @function

     * @param {Object} media
     * @returns {Promise<Response>} - The newly created user
     */

    static Update( media) {
        return this.api().patch(`/media/${media.id}`, media, {
                dataTransformer: ({data: {data}}) => {

                    // if (relationships.includes('user')) {
                    //     Object.assign(task.attributes.user, task.attributes.user.attributes)
                    // }
                    return {...data, ...data.attributes}
                },
            }
        )
    }
    /**
     * Store a new media by file path
     * @function
     * @param {FormData} data
     * @returns {Promise<Response>} - The newly created user
     */

    static StoreByPath(data) {
        return this.api().post(`/media-float`, data, {
                dataTransformer: ({data: {data}}) => {

                    return {...data, ...data.attributes}
                },
            }
        )
    }

    /**
     * Delete a media item
     * @function
     * @param {number} id - The id of the media item
     * @returns {Promise<Response>}
     */

    static Delete(id) {
        return this.api().delete(`/media/${id}`, {
                delete: id

            }
        )
    }


}
