import {TransformModel} from "@/models/TransformModel";
import Subject from "@/models/Subject";
import User from "@/models/User";
import Stage from "@/models/Stage";
import Phase from "@/models/Phase";
import EnrolmentStatus from "@/models/EnrolmentStatus";
import HomeClass from "@/models/HomeClass";
import Extramural from "@/models/Extramural";

/**
 * A enrolment
 * @property {number} id
 * @property {number} campus_id - The id of the campus this enrolment belongs to
 * @property {number} stage_id - The id of the stage this enrolment belongs to
 * @property {number} phase_id - The id of the phase this enrolment belongs to
 * @property {number} user_id - The id of the user this enrolment belongs to
 * @property {number} year - The year of this enrolment belongs to
 * @property {number} status_id - the id of the status of the enrolment
 */
export default class Enrolment extends TransformModel {
    static entity = 'enrolments'

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            user: this.belongsTo(User, 'user_id'),
            phase_id: this.attr(null),
            phase: this.belongsTo(Phase, 'phase_id'),
            campus_id: this.attr(null),
            year: this.attr(null),
            stage_id: this.attr(null),
            stage: this.belongsTo(Stage, 'stage_id'),
            home_class_id: this.attr(null),
            home_class: this.belongsTo(HomeClass, 'home_class_id'),
            status_id: this.attr(null),
            status: this.belongsTo(EnrolmentStatus, 'status_id'),
            subject_ids: this.attr(() => []),
            subjects: this.hasManyBy(Subject, 'subject_ids'),
            extramural_ids: this.attr(() => []),
            extramurals: this.hasManyBy(Extramural, 'extramural_ids'),
            enroled_date: this.attr(null),
            graduated_date: this.attr(null),
            withdrawn_date: this.attr(null),
            finance_type_id: this.attr(1),
            type_id: this.attr(1),
            amount: this.attr(0)
        }
    }

    /**
     * Returns all enrolments
     * @function
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {?Object} [query={}] - Query terms for the request
     * @param {?number} [query.campus_id]
     * @param {number} [query.stage_id]
     * @param {number} [query.phase_id]
     * @param {number} [query.user_id]
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchAll({page = 1, limit = 15}, query = {}, relationships = []) {
        return this.api().get(`/enrolments`, {
                params: {
                    ...{
                        page: page, limit: limit, with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(enrolment => {
                        if (relationships.includes('subjects')) {
                            enrolment.attributes.subjects.map(subject => {
                                Object.assign(subject, subject.attributes)
                            })
                        }
                        if (relationships.includes('extramurals')) {
                            enrolment.attributes.extramurals.map(extramural => {
                                Object.assign(extramural, extramural.attributes)
                            })
                        }
                        if (relationships.includes('home_class')) {
                            if (Object.prototype.hasOwnProperty.call(enrolment.attributes, 'home_class')) {
                                if (enrolment.attributes.home_class !== null) {
                                    Object.assign(enrolment.attributes.home_class, enrolment.attributes.home_class.attributes)
                                }
                            }

                        }
                        if (relationships.includes('user')) {
                            Object.assign(enrolment.attributes.user, enrolment.attributes.user.attributes)

                        }
                        if (relationships.includes('phase')) {

                            Object.assign(enrolment.attributes.phase, enrolment.attributes.phase.attributes)

                        }
                        if (relationships.includes('stage')) {
                            Object.assign(enrolment.attributes.stage, enrolment.attributes.stage.attributes)

                        }
                        return {...enrolment, ...enrolment.attributes}
                    })
                },
            }
        )
    }

    /**
     * Returns a enrolment by its id
     * @function

     * @param {number} id The id of the enrolment
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchById(id, relationships = []) {
        return this.api().get(`/enrolments/${id}`, {
                params: {
                    with: relationships
                },
                dataTransformer: ({data: {data}}) => {
                    if (relationships.includes('subjects')) {
                        data.attributes.subjects.map(subject => {

                            Object.assign(subject, subject.attributes)
                            if (relationships.includes('subjects.class_groups')){
                                if (Object.prototype.hasOwnProperty.call(subject.attributes,'class_groups')) {
                                    subject.attributes.class_groups.map(group => {
                                        Object.assign(group, group.attributes)
                                    })
                                }
                            }
                            subject.is_selected = 1
                        })
                    }
                    if (relationships.includes('extramurals')) {
                        data.attributes.extramurals.map(extramural => {
                            Object.assign(extramural, extramural.attributes)
                        })
                    }
                    if (relationships.includes('home_class')) {
                        if (Object.prototype.hasOwnProperty.call(data.attributes, 'home_class')) {
                            if (data.attributes.home_class !== null) {
                                Object.assign(data.attributes.home_class, data.attributes.home_class.attributes)
                            }
                        }

                    }
                    if (relationships.includes('user')) {
                        Object.assign(data.attributes.user, data.attributes.user.attributes)
                    }
                    if (relationships.includes('stage')) {
                        Object.assign(data.attributes.stage, data.attributes.stage.attributes)
                    }
                    if (relationships.includes('phase')) {
                        Object.assign(data.attributes.phase, data.attributes.phase.attributes)
                    }
                    return {...data, ...data.attributes}

                },
            }
        )
    }

    /**
     * Returns all enrolments in a subject by its id
     * @function
     * @param {number} id The id of the subject
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {?Object} [query={}] - Query terms for the request
     * @param {?number} [query.year]
     * @param {?number} [query.status_id]
     * @param {?string} [persistBy]
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchEnrolmentsBySubjectId(id, relationships = [], {
        page = 1,
        limit = 999
    }, query = {}, persistBy = 'insertOrUpdate') {
        return this.api().get(`/subjects/${id}/enrolments`, {
                persistBy: persistBy,
                params: {
                    ...{
                        page: page, limit: limit, with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(enrolment => {
                        if (relationships.includes('subjects')) {
                            enrolment.attributes.subjects.map(subject => {
                                Object.assign(subject, subject.attributes)
                            })
                        }
                        if (relationships.includes('extramurals')) {
                            enrolment.attributes.extramurals.map(extramural => {
                                Object.assign(extramural, extramural.attributes)
                            })
                        }
                        if (relationships.includes('user')) {
                            Object.assign(enrolment.attributes.user, enrolment.attributes.user.attributes)

                        }
                        if (relationships.includes('phase')) {

                            Object.assign(enrolment.attributes.phase, enrolment.attributes.phase.attributes)

                        }
                        if (relationships.includes('stage')) {
                            Object.assign(enrolment.attributes.stage, enrolment.attributes.stage.attributes)

                        }
                        return {...enrolment, ...enrolment.attributes}
                    })
                },
            }
        )
    }

    /**
     * Returns all enrolments in a extramural by its id
     * @function
     * @param {number} id The id of the extramural
     * @param {Object} pagination
     * @param {number} pagination.page - Which page to retrieve
     * @param {number} pagination.limit - How many entities to retrieve
     * @param {?Object} [query={}] - Query terms for the request
     * @param {?number} [query.year]
     * @param {?number} [query.status_id]
     * @param {?string} [persistBy]
     * @param {Array.<string>} [relationships=[]] - Relationships to bring along
     * @returns {Promise<Response>}
     */

    static FetchEnrolmentsByExtramuralId(id, relationships = [], {
        page = 1,
        limit = 999
    }, query = {}, persistBy = 'insertOrUpdate') {
        return this.api().get(`/extramurals/${id}/enrolments`, {
                persistBy: persistBy,
                params: {
                    ...{
                        page: page, limit: limit, with: relationships
                    }, ...(query !== {} ? query : {}),
                },
                dataTransformer: ({data: {data}}) => {
                    return data.map(enrolment => {
                        if (relationships.includes('extramurals')) {
                            enrolment.attributes.extramurals.map(extramural => {
                                Object.assign(extramural, extramural.attributes)
                            })
                        }

                        if (relationships.includes('user')) {
                            Object.assign(enrolment.attributes.user, enrolment.attributes.user.attributes)

                        }
                        if (relationships.includes('phase')) {

                            Object.assign(enrolment.attributes.phase, enrolment.attributes.phase.attributes)

                        }
                        if (relationships.includes('stage')) {
                            Object.assign(enrolment.attributes.stage, enrolment.attributes.stage.attributes)

                        }
                        return {...enrolment, ...enrolment.attributes}
                    })
                },
            }
        )
    }

    /**
     * Store a new enrolment
     * @function
     * @param {Object} enrolment - The enrolment object
     * @param {number} enrolment.campus_id - The id of the campus the enrolment is associated with
     * @param {number} enrolment.stage_id - The id of the stage the enrolment is associated with
     * @param {number} enrolment.phase_id - The id of the phase the enrolment is associated with
     * @param {number} enrolment.user_id - The id of the user the enrolment is associated with
     * @param {number} enrolment.status_id - The status of the enrolment
     * @param {number} enrolment.year - The year of the enrolment
     * @param {Array<number>} enrolment.subjects - The ids of the subjects of the enrolment
     * @param {Array<number>} enrolment.extramurals - The ids of the extramurals of the enrolment
     * @returns {Promise<Response>} - The newly created enrolment
     */

    static Store(enrolment) {
        return this.api().post(`/enrolments`, enrolment, {
                dataTransformer: ({data: {data}}) => {
                    // if (relationships.includes('subjects')) {
                    //     data.attributes.subjects.map(subject => {
                    //         Object.assign(subject, subject.attributes)
                    //     })
                    // }
                    // if (relationships.includes('user')) {
                    //     Object.assign(data.attributes.user, data.attributes.user.attributes)
                    // }
                    // if (relationships.includes('stage')) {
                    //     Object.assign(data.attributes.stage, data.attributes.stage.attributes)
                    // }
                    // if (relationships.includes('phase')) {
                    //     Object.assign(data.attributes.phase, data.attributes.phase.attributes)
                    // }
                    return {...data, ...data.attributes}

                },
            }
        )
    }
    /**
     * Store multiple enrolments
     * @function
     * @param {Object} enrolments - The enrolments object
     * @returns {Promise<Response>} - The newly created enrolment
     */

    static StoreBulk(enrolments) {
        return this.api().post(`/enrolments/bulk-insert`, enrolments, {
            dataTransformer: ({data: {data}}) => {
                return data.map(enrolment => {

                    return {...enrolment, ...enrolment.attributes}
                })
            },
            }
        )
    }

    /**
     * Update an existing enrolment
     * @function
     * @param {Object} enrolment - The enrolment object
     * @param {number} enrolment.campus_id - The id of the campus the enrolment is associated with
     * @param {number} enrolment.stage_id - The id of the stage the enrolment is associated with
     * @param {number} enrolment.phase_id - The id of the phase the enrolment is associated with
     * @param {number} enrolment.user_id - The id of the user the enrolment is associated with
     * @param {number} enrolment.status_id - The status of the enrolment
     * @param {number} enrolment.year - The year of the enrolment
     * @param {number} enrolment.id - The id of the enrolment
     * @param {Array<number>} enrolment.subjects - The ids of the subjects of the enrolment
     * @param {Array<number>} enrolment.extramurals - The ids of the extramurals of the enrolment
     * @param {boolean} saved - Whether to persist the response
     * @returns {Promise<Response>} - The newly created enrolment
     */

    static Update(enrolment, saved) {
        enrolment.extramurals = enrolment.extramural_ids
        return this.api().patch(`/enrolments/${enrolment.id}`, enrolment, {
                dataTransformer: ({data: {data}}) => {
                    return {...data, ...data.attributes}
                },
                save: saved
            }
        )
    }

    /**
     * Delete an existing enrolment
     * @function
     * @param {number} enrolment_id - The id of the enrolment
     * @returns {Promise<Response>} - The newly created enrolment
     */

    static Delete(enrolment_id) {
        return this.api().delete(`/enrolments/${enrolment_id}`, {
                delete: enrolment_id
            }
        )
    }


}
