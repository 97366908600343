<template>
  <li v-if="hasPermission" ref="theElement" :class="{'is-active':isDropdownActive,'menu-list-container':hasDropdown}"
      @mouseenter="broadcastPosition">
    <router-link
        v-if="componentIs==='router-link'"
        v-slot="{isExactActive}"
        :class="{'has-icon':!!item.icon,'open':is_open}"
        :title="item.label"
        :to="itemTo"
        class="aside-top-level-item is-relative "
        exact-active-class="is-active"
        @click="menuClick"
    >
      <a :class="{'is-active':(isExactActive||force_active)}" class="level pl-5 aside-menu-item is-mobile "
         @click.prevent.stop="linkClick(itemTo)">
        <div class="level-left no-overflow">
          <div class="level-item no-overflow">
            <b-icon
                v-if="item.icon"
                :class="{ 'has-update-mark' : item.updateMark, 'is-tiny': isSub, }"
                :icon="item.icon"
                :pack="(isExactActive||force_active)?'filled':'outline'"
                :type="(isExactActive||force_active) ? 'is-primary' : 'is-grey'"
                custom-size="default"
            />

            <span v-if="item.label"
                  :class="{'fadeIn animated':true,'fadeOut animated':true,'menu-item-label':!!item.icon,'pl-6':!item.icon,'is-hidden-touch':!$store.state.menu.isAsideMobileExpanded,'has-text-primary':(isExactActive||force_active)}"
                  class="">{{
                item.label
              }}</span>
          </div>
        </div>

      </a>
    </router-link>
    <a
        v-if="componentIs==='a'"
        :class="{'has-icon':!!item.icon,'open':is_open}"
        :href="itemHref"
        :title="item.label"
        class="is-relative "
        exact-active-class="is-active"
        @click="menuClick"
    >
      <div :class="{'is-active':force_active}" class="level is-mobile pl-5 aside-menu-item">
        <div class="level-left no-overflow">
          <div class="level-item no-overflow">
            <b-icon
                v-if="item.icon"
                :class="{ 'has-update-mark' : item.updateMark }"
                :icon="item.icon"
                :pack="force_active ? 'filled' : 'outline'"
                :type="force_active ? 'is-primary' : 'is-grey'"
                custom-size="default"
            />
            <span v-if="item.label"
                  :class="{'fadeIn animated':!isSub,'fadeOut animated':!isSub,'menu-item-label':!!item.icon,'is-hidden-touch':!$store.state.menu.isAsideMobileExpanded,'has-text-primary':force_active}">{{
                item.label
              }}</span>
          </div>

        </div>
        <div
            :class="{'is-hidden-desktop':!$store.state.menu.isAsideExpanded,'is-hidden-touch':!$store.state.menu.isAsideMobileExpanded,'fadeIn animated':true,'fadeOut animated':true,}"
            class="level-right ">
          <div class="level-item">
            <b-icon :icon="hasDropdown && isDropdownActive ? 'arrow-up' : 'arrow-down'" class="is-tiny"
                    type="is-grey"/>
          </div>
        </div>
      </div>
    </a>

    <aside-menu-list v-if="hasDropdown" :isSubmenuList="true" :menu="item.menu"/>
  </li>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "AsideMenuItem",

  data() {
    return {
      showHover: false
    };
  },
  props: {
    hasFadeIn: {
      type: Boolean,
      default: false
    }, hasFadeOut: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: null,
    }, isSub: {
      type: Boolean, default() {
        return false
      }
    },
  },
  methods: {
    linkClick(to) {
      this.$store.dispatch('menu/activateDropdown', this.item.name.split('.')[0])
      this.$router.push(to)
    },
    broadcastPosition() {
      if (this.hasDropdown) {
        this.$store.dispatch('popout/setTop', this.$refs.theElement.getBoundingClientRect().top)
        this.$store.dispatch('popout/setBottom', this.$refs.theElement.getBoundingClientRect().bottom)
        this.$store.dispatch('popout/setItem', this.item)
        this.$store.dispatch('popout/setDisplay', true)
      }
    },
    menuClick() {
      this.$emit("menu-click", this.item);

      if (this.hasDropdown) {
        this.isDropdownActive = !this.isDropdownActive;
        if (!this.isAsideMobileExpanded && this.$store.getters['size/isMobile']) {
          this.$store.dispatch("menu/asideMobileStateToggle", true);
        }
        if (!this.isAsideExpanded && !this.$store.getters['size/isMobile']) {
          this.$store.dispatch("menu/asideStateToggle", true);
        }
      }
    },
  },
  computed: {
    isDropdownActive: {
      set(val) {
        if (val) {
          this.$store.dispatch('menu/activateDropdown', this.item.name)
        } else {
          this.$store.dispatch('menu/activateDropdown', null)

        }
      },
      get() {
        return this.$store.state.menu.activeDropdown === this.item.name
      }
    },
    force_active() {
      let bool = false
      if (this.$route.meta.highlight) {
        bool = this.$route.meta.highlight === this.item.name;
      }
      if (this.$store.state.menu.openDropdown === this.item.name) {
        bool = true
      }
      return bool
    },
    is_open() {
      let bool = false;
      if (this.$route.meta.highlight) {
        bool = this.$route.meta.highlight === this.item.name;
      } else if (this.$route.name) {
        bool = this.$route.name.includes(this.item.name);
      }
      return bool;
    },
    hasPermission() {
      if (this.item.permission) {
        return this.$store.getters["entities/user-permissions/find"](
            this.item.permission
        );
      }
      if (this.item.permissions) {
        return this.item.permissions.some(permission => this.$store.getters["entities/user-permissions/find"](
            permission
        ))
      }
      return true;
    },
    componentIs() {
      return this.item.to ? "router-link" : "a";
    },
    hasDropdown() {
      return !!this.item.menu;
    },
    itemTo() {
      return this.item.to ? this.item.to : null;
    },
    itemHref() {
      return this.item.href ? this.item.href : null;
    },
    ...mapState('menu', ["isAsideExpanded", "isAsideMobileExpanded"]),
  },
  watch: {
    isAsideExpanded(newValue) {
      if (!newValue) {
        this.isDropdownActive = false;
      }
    },
  },
};
</script>
