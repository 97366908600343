<template>
  <div :class="{'modal-card':inModal}" class="documentation-window">
    <header class="modal-card-head ">

      <span class="modal-card-title has-text-weight-semibold is-size-3 is-flex is-align-items-center is-justify-content-space-between">Submit a Ticket</span>

    </header>

    <PerfectScrollbarWrapper class="has-background-white">

      <section :class="{'modal-card-body':inModal}">
        <b-field label="Category">
            <b-select
                required
                expanded
                v-model="ticketObject.category"
                placeholder="Select Category"
            >
                <option
                    v-for="category of this.categories"
                    :key="category"
                    :value="category"
                >{{ category }}
                </option>
            </b-select>
        </b-field>
        <b-field label="Page URL">
          <b-input
              v-model="ticketObject.pageUrl"
              placeholder="URL of Page With Issue"
              required
              type="url">
          </b-input>
        </b-field>
        <b-field label="Description">
          <b-input
              v-model="ticketObject.description"
              placeholder="Describe the issue"
              required
              type="textarea">
          </b-input>
        </b-field>

        <MediaPanel
            v-if="!loading"
            :submit_button="false"
            :submit_flip="submitMedia"
            @submitted="submitMedia=false;$emit('close')"
            :title="'Ticket Media'" :model="'tickets'"
            :has-tag="false"
            :model_id="parseInt(ticketObject.id)"
            :canDelete="!!$store.getters['entities/user-permissions/find']('delete media')"
            :canCreate="!!$store.getters['entities/user-permissions/find']('create media')"
        ></MediaPanel>
      </section>
    </PerfectScrollbarWrapper>
    
    <footer class="modal-card-foot">
        <b-field class="mt-3 has-text-centered" grouped expanded position="is-centered">
          <b-field position="is-centered">
            <b-button native-type="submit"
                      @click="submit"
                      :disabled="!this.ticketObject.category || !this.ticketObject.pageUrl || !this.ticketObject.description"
                      type="is-primary"
                      :icon-right="$tc(`icons.edit`)">
              Submit
            </b-button>
          </b-field>
        </b-field>
    </footer>
  </div>
</template>

<script>

import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";
import MediaPanel from "@/components/media/MediaPanel";
import SupportTicket from "@/models/SupportTicket";

export default {
  name: 'SubmitSupportTicketWindow',
  components: {
    PerfectScrollbarWrapper,
    MediaPanel,
  },
  data() {
    return {
      isActive: true,
      loading: false,
      submitMedia: false,
      ticketObject: {
        id: null,
        category: null,
        pageUrl: null,
        description: null,
        media: null,
      },
      categories: ["Error", "Page not responding", "Need assistance", "Other"], //TODO NW: "Other" needs to open a text field
    }
  },
  props: {
    inModal: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  methods: {
    submit() {
      let submitObject = 
      {
        category: this.ticketObject.category,
        page_url: this.ticketObject.pageUrl,
        description: this.ticketObject.description,
      };
      if(!this.loading)
      {
        this.loading = true;
        SupportTicket.Store(submitObject).then(({entities: {tickets}}) => {
          this.$buefy.snackbar.open(`Ticket created!`)
          // this.$emit('update-tickets')
          this.eventObject.id = tickets[0].id
          // this.showMedia = true
          this.loading = false
          this.submitMedia = true
        }).catch(err => {
          this.handleError(err)
          this.loading = false
        })
      }

    }
  },
  mounted() {
    if (this.inModal) {
      this.makeModalLarge()
    }
    this.ticketObject.id
  }

}
</script>
