/* Functionality Imports */
import Vue from 'vue'
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'
import axios from 'axios'
import VuexORMSearch from "@vuex-orm/plugin-search";


import VueCookies from 'vue-cookies'


let accessToken = VueCookies.get('access_token')
let defaultHeaders = {
    'X-Requested-With': 'XMLHttpRequest',
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};

if (accessToken) {
    defaultHeaders['Authorization'] = 'Bearer ' + accessToken;
}
VuexORM.use(VuexORMAxios, {
    axios, baseURL: process.env.VUE_APP_API_URL, headers: {
        ...defaultHeaders
    }
})
VuexORM.use(VuexORMSearch, {})

Vue.use(Vuex)

const database = new VuexORM.Database()

import User from "@/models/User";
import Permission from "@/models/Permission";


import {AuthModule} from "@/store/modules/Auth";
import {LoaderModule} from "@/store/modules/Loader";
import {MenuModule} from "@/store/modules/Menu";
import {ToastModule} from "@/store/modules/Toast";
import {UserModule} from "@/store/modules/User";
import PermissionRole from "@/models/PermissionRole";
import Role from "@/models/Role";
import RoleUser from "@/models/RoleUser";
import {SizeModule} from "@/store/modules/Size";
import {PopoutModule} from "@/store/modules/Popout";
import Campus from "@/models/Campus";
import Phase from "@/models/Phase";
import Stage from "@/models/Stage";
import Subject from "@/models/Subject";
import {CampusModule} from "@/store/modules/Campus";
import Extramural from "@/models/Extramural";
import Enrolment from "@/models/Enrolment";
import CampusUser from "@/models/CampusUser";
import SubjectUser from "@/models/SubjectUser";
import EnrolmentStatus from "@/models/EnrolmentStatus";
import TaskType from "@/models/TaskType";
import Task from "@/models/Task";
import Profile from "@/models/Profile";
import Lookup from "@/models/Lookup";
import Dashboard from "@/models/Dashboard";
import LearningStream from "@/models/LearningStream";
import UserPermission from '@/models/UserPermission'
import Application from "@/models/Application";
import ApplicationStage from "@/models/ApplicationStage";
import ApplicationStatus from "@/models/ApplicationStatus";
import {ApplicationModule} from "@/store/modules/Application";
import Media from "@/models/Media";
import Note from "@/models/Note";
import LessonPlan from "@/models/LessonPlan";
import UnitObjective from "@/models/UnitObjective";
import Service from "@/models/Service";
import HomeClass from "@/models/HomeClass";
import Attendance from "@/models/Attendance";
import {AttendanceModule} from "@/store/modules/Attendance";
import AttendanceDashboard from "@/models/AttendanceDashboard";
import CourseScope from "@/models/CourseScope";
import Topic from "@/models/Topic";
import Objective from "@/models/Objective";
import Unit from "@/models/Unit";
import TeacherReflection from "@/models/TeacherReflection";
import UserRecord from "@/models/UserRecord";
import {DateSelectModule} from "@/store/modules/Dates";
import LearnerReflection from "@/models/LearnerReflection";
import Term from "@/models/Term";
import {AssessmentModule} from "@/store/modules/Assessment";
import Assessment from "@/models/Assessment";
import Grade from "@/models/Grade";
import Report from "@/models/Report";
import ReportSubject from "@/models/ReportSubject";
import {ReportModule} from "@/store/modules/Report";
import ReportMarkGroup from "@/models/ReportMarkGroup";
import ReportSubjectGradeThreshold from "@/models/ReportSubjectGradeThreshold";
import ReportSubjectGradeTotals from "@/models/ReportSubjectGradeTotals";
import ReportSubjectComment from "@/models/ReportSubjectComment";
import ReportUserComment from "@/models/ReportUserComment";
import ClassGroup from "@/models/ClassGroup";
import UserReport from "@/models/UserReport";
import LeaveRequest from "@/models/LeaveRequest";
import {LeaveRequestModule} from "@/store/modules/LeaveRequest";
import {ServiceModule} from "@/store/modules/Service";
import Package from "@/models/Package";
import UserNotification from "@/models/UserNotification";
import SportsHouse from "@/models/SportsHouse";
import ReportSubjectAssessmentGrades from "@/models/ReportSubjectAssessmentGrades";
import ILP from "@/models/ILP";
import ProgressType from "@/models/ProgressType";
import {ProgressTableModule} from "@/store/modules/ProgressTable";
import ILPTopic from "@/models/ILPTopic";
import ILPUnit from "@/models/ILPUnit";
import ILPObjective from "@/models/ILPObjective";
import ILPStudentProgress from "@/models/ILPStudentProgress";
import Document from "@/models/Document";
import Folder from "@/models/Folder";
import {IncidentModule} from "@/store/modules/Incident";
import Incident from "@/models/Incident";
import Announcement from "@/models/Announcement";
import {AnnouncementModule} from "@/store/modules/Announcement";
import {StaffProfileModule} from "@/store/modules/StaffProfile";
import Department from "@/models/Department";
import StaffProfile from "@/models/StaffProfile";
import EventType from "@/models/EventType";
import CalendarEvent from "@/models/CalendarEvent";
import {CalendarModule} from "@/store/modules/Calendar";
import AnnouncementModel from "@/models/AnnouncementModel";
import {DocumentationModule} from "@/store/modules/Documentation";
import AssessmentGradeThreshold from "@/models/AssessmentGradeThreshold";
import Level from "@/models/Level";
import ReportExtramural from "@/models/ReportExtramural";
import ReportExtramuralComment from "@/models/ReportExtramualComment";
import {ProfileModule} from "@/store/modules/Profile";
import {CovidAssessmentModule} from "@/store/modules/CovidAssessment";
import CovidAssessment from "@/models/CovidAssessment";
import Lesson from "@/models/Lesson";
import ReportFinalGroup from "@/models/ReportFinalGroup";
import ReportFinalGroupOption from "@/models/ReportFinalGroupOption";
import Setting from "@/models/Setting";
import Programme from "@/models/Programme";
import UserRecordType from "@/models/UserRecordType";

database.register(Permission)
database.register(PermissionRole)
database.register(Role)
database.register(RoleUser)
database.register(Campus)
database.register(Phase)
database.register(Stage)
database.register(Subject)
database.register(Extramural)
database.register(Enrolment)
database.register(User)
database.register(CampusUser)
database.register(SubjectUser)
database.register(EnrolmentStatus)
database.register(TaskType)
database.register(Task)
database.register(Profile)
database.register(Lookup)
database.register(Dashboard)
database.register(LearningStream)
database.register(UserPermission)
database.register(Application)
database.register(ApplicationStage)
database.register(ApplicationStatus)
database.register(Media)
database.register(Note)
database.register(CourseScope)
database.register(Topic)
database.register(Objective)
database.register(Unit)
database.register(LessonPlan)
database.register(UnitObjective)
database.register(Service)
database.register(HomeClass)
database.register(Attendance)
database.register(AttendanceDashboard)
database.register(CourseScope)
database.register(Topic)
database.register(Objective)
database.register(Unit)
database.register(TeacherReflection)
database.register(UserRecord)
database.register(LearnerReflection)
database.register(Term)
database.register(Assessment)
database.register(Grade)
database.register(Report)
database.register(ReportSubject)
database.register(ReportMarkGroup)
database.register(ReportSubjectGradeThreshold)
database.register(ReportSubjectGradeTotals)
database.register(ReportSubjectAssessmentGrades)
database.register(Incident)
database.register(Announcement)
database.register(Document)
database.register(Folder)
database.register(Announcement)
database.register(Incident)
database.register(ILP)
database.register(ILPTopic)
database.register(ILPUnit)
database.register(ILPObjective)
database.register(ILPStudentProgress)
database.register(Incident)
database.register(Announcement)
database.register(Document)
database.register(Folder)
database.register(ReportSubjectComment)
database.register(ReportUserComment)
database.register(ClassGroup)
database.register(UserReport)
database.register(Department)
database.register(StaffProfile)
database.register(LeaveRequest)
database.register(Package)
database.register(SportsHouse)
database.register(UserNotification)
database.register(ProgressType)
database.register(EventType)
database.register(CalendarEvent)
database.register(AnnouncementModel)
database.register(ProgressType)
database.register(AssessmentGradeThreshold)
database.register(Level)
database.register(ReportExtramural)
database.register(ReportExtramuralComment)
database.register(CovidAssessment)
database.register(Lesson)
database.register(ReportFinalGroup)
database.register(ReportFinalGroupOption)
database.register(Programme)
database.register(UserRecordType)
database.register(Setting)
export default new Vuex.Store({
    modules: {
        auth: AuthModule,
        loader: LoaderModule,
        menu: MenuModule,
        toast: ToastModule,
        user: UserModule,
        size: SizeModule,
        popout: PopoutModule,
        campus: CampusModule,
        application: ApplicationModule,
        attendance: AttendanceModule,
        dates: DateSelectModule,
        assessments: AssessmentModule,
        progressTable: ProgressTableModule,
        reports: ReportModule,
        announcements: AnnouncementModule,
        calendar: CalendarModule,
        staffProfile: StaffProfileModule,
        profile: ProfileModule,
        leave: LeaveRequestModule,
        incidents: IncidentModule,
        services: ServiceModule,
        documentation: DocumentationModule,
        covid:CovidAssessmentModule,
    },
    plugins: [VuexORM.install(database)],

})
