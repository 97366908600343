<template>
  <div id="app">
    <nav-bar v-if="authenticated"/>
    <aside-menu v-if="authenticated && type==='staff'" ref="staff-menu" :menu="menu" :show-menu-expand="true"/>
    <aside-menu v-if="authenticated&& type==='guardian'" ref="guardian-menu" :menu="guardianMenu"
                :show-menu-expand="true"/>
    <router-view id="app-view"/>
    <aside-right :docs-type="$store.state.auth.type"></aside-right>
    <b-loading ref="pageLoader" :active.sync="loading" :can-cancel="false" :is-full-page="true"/>
  </div>
</template>

<script>
import NavBar from "@/components/layout/NavBar";
import AsideMenu from "@/components/layout/AsideMenu";
import router from '@/router'
import {mapState} from 'vuex'
import Profile from '@/models/Profile'
import AsideRight from "@/components/layout/AsideRight";
import User from "@/models/User";

import update from './mixins/Update'
export default {
  components: {
    AsideRight,
    AsideMenu, NavBar
  },
  mixins: [update],
  data() {
    return {
      hasProfile: true,
      guardianWards: null,
      menu: [
        {label: 'Home', icon: router.app.$tc('icons.home'), name: 'home', to: '/'},
        {
          label: 'Dashboard',
          icon: router.app.$tc('icons.dashboard'),
          name: 'dashboard',
          to: '/dashboard'
        },
        {
          label: 'Academics',
          icon: router.app.$tc('icons.learners'),
          name: 'academics',
          permissions: ["view lesson-plans",
            "view reports",
            "view course-scopes",
            "view objectives"],
          menu: [
            {
              label: "Subjects",
              to: '/lesson-plans',
              name: 'academics.lesson-plans.index',
              // icon: router.app.$tc("icons.subjects"),
              permission: "view lesson-plans",
            },
            {
              label: "Reports",
              to: '/reports',
              name: 'academics.reports.index',
              // icon: router.app.$tc("icons.reports"),
              permission: "view reports",
            },
            {
              label: "Courses",
              to: '/courses',
              name: 'academics.courses.index',
              // icon: router.app.$tc("icons.courses"),
              permission: "view course-scopes",
            }, {
              label: "Objective Manager",
              to: '/objectives',
              name: 'academics.objectives.index',
              // icon: router.app.$tc("icons.objectives"),
              permission: "view objectives",
            },
          ]
        },
        {
          label: 'Students',
          icon: router.app.$tc("icons.students"),
          name: 'students',
          permissions: ["process applications",
            "view enrolments"],
          menu: [
            {
              label: "Applications",
              to: '/applications',
              name: 'students.applications.index',
              // icon: router.app.$tc("icons.applications"),
              permission: "process applications",
            }, {
              label: "Enrolments",
              to: '/enrolments',
              name: 'students.enrolments.dashboard',
              // icon: router.app.$tc("icons.enrolments"),
              permission: "view enrolments",
            }]
        },
        {
          label: 'Users',
          icon: router.app.$tc("icons.users"),
          permissions: ['view users', 'view learners', 'view guardians'],
          name: 'users',
          menu: [{
            label: "Staff",
            // icon: router.app.$tc("icons.staff"),
            permission: "view users",
            name: "users.staff",
            to: '/staff'
          }, {
            label: "Guardians",
            // icon: router.app.$tc("icons.guardians"),
            permission: "view guardians",
            name: "users.guardians",
            to: '/guardians'
          }, {
            label: this.$tc('Learner', 2),
            // icon: router.app.$tc("icons.learners"),
            permission: "view learners",
            name: "users.learners",
            to: '/learners'
          },]
        },
        {
          label: "My HomeClasses",
          to: '/my-classes',
          name: 'classes.index',
          icon: router.app.$tc("icons.homeClasses"),
          permission: "view homeclass",
        }, {
          label: "Attendances",
          to: '/attendances',
          name: 'attendances.index',
          icon: router.app.$tc("icons.attendances"),
          permission: "view attendances",
        },

        {
          label: 'Human Resources',
          icon: router.app.$tc("icons.human-resources"),
          permissions: ['manage leave requests', 'administrate incidents'],
          name: 'human-resources',
          menu: [{
            label: "Leave Requests",
            to: '/leave',
            name: 'human-resources.leave.index',
            // icon: router.app.$tc("icons.leave"),
            permission: "manage leave requests",
          }, {
            label: "Incidents",
            // icon: router.app.$tc("icons.incidents"),
            permission: "administrate incidents",
            name: "human-resources.incidents.index",
            to: '/incidents'
          },]
        },
        {
          label: 'Operations',
          icon: router.app.$tc('icons.operations'),
          name: 'operations',
          permissions: ["view tasks",
            'administrate announcements', 'create announcements',
            "view roles",
            "view exports"],
          menu: [
            {
              label: "Activities",
              to: '/activities',
              name: 'operations.activities.index',
              // icon: router.app.$tc("icons.activities"),
              permission: "view tasks",
            }, {
              permissions: ['administrate announcements', 'create announcements'],
              label: "Announcements",
              to: '/announcements',
              name: 'operations.announcements.index',
              // icon: router.app.$tc("icons.activities"),
            }, {
              label: "Roles",
              to: '/roles',
              name: 'operations.roles.dashboard',
              // icon: router.app.$tc("icons.roles"),
              permission: "view roles",
            }, {
              label: "Exports",
              to: '/exports',
              name: 'operations.exports.index',
              // icon: router.app.$tc("icons.exports"),
              permission: "view exports",
            },
          ]
        },

        {
          label: "Campus Admin",
          to: '/campuses',
          name: 'campuses.dashboard',
          icon: router.app.$tc("icons.campuses"),
          permissions: ['edit campuses', 'edit phases', 'edit stages', 'edit subjects', 'edit extramurals', 'edit terms', 'edit services'],
        }]
    }
  }, computed: {

    guardianMenu() {

      return [


        ...(this.$store.state.auth.canAccessGuardianHome ? [{
          label: 'Home',
          icon: router.app.$tc('icons.home'),
          name: 'guardian-home',
          to: '/g/'
        }] : []), ...(this.hasProfile ? [{
          label: 'My Profile', icon: router.app.$tc("icons.users"), name: 'guardian-profile', to: '/g/my-profile'
        }] : []),
        ...[{
          label: 'My Applications',
          icon: router.app.$tc("icons.applications"),
          name: 'guardian-applications',
          to: '/g/applications'
        }, {
          label: `My ${this.$tc('Learner', 2)}`,
          icon: router.app.$tc("icons.learners"),
          name: 'guardian-wards',
          menu: this.guardianWards ? this.guardianWards.map(ward => {
            return {label: ward.first_name, name: `guardian-wards.${ward.id}`, to: `/g/my-wards/${ward.id}`}
          }) : []
        }, {
          label: `Progress`,
          icon: router.app.$tc("icons.learners"),
          name: 'guardian-progress',
          menu: this.guardianWards ? this.guardianWards.map(ward => {
            return {label: ward.first_name, name: `guardian-progress.${ward.id}`, to: `/g/progress/${ward.id}`}
          }) : []
        }
          // ,
          //   {
          //     label: 'New Application',
          //     icon: router.app.$tc("icons.create"),
          //     name: 'guardian-application',
          //     to: '/g/applications/create'
          //   }
        ]]
    },
    ...mapState("loader", ['loading', 'overlay']), ...mapState("size", ['height', 'width']), ...mapState('popout', ['top', 'display']), ...mapState('auth', ['authenticated', 'type'])
  },
  watch: {
    type(newVal) {
      if (newVal === 'guardian') {

        User.FetchById(parseInt(this.$store.state.user.id), ['wards']).then(response => {
          if (response.response.data.data.attributes.wards.length > 0) {
            this.guardianWards = response.response.data.data.attributes.wards.map(ward => {
              return {
                id: ward.id,
                first_name: ward.attributes.first_name
              }
            })


          }
        }).catch(err => {

          this.handleError(err)

        });


      }
    }
  },
  mounted() {
    if (this.authenticated && this.type == 'guardian') {
      Profile.FetchById(this.$store.state.user.id).then(() => {
        this.hasProfile = true
      })
    }
    window.addEventListener('resize', () => {
      this.$store.dispatch('size/setHeight', window.innerHeight)
      this.$store.dispatch('size/setWidth', window.innerWidth)
    })
  }, beforeDestroy() {
    window.removeEventListener('resize', () => {
      this.$store.dispatch('size/setHeight', window.innerHeight)
      this.$store.dispatch('size/setWidth', window.innerWidth)
    })
  },
}
</script>
<style>
</style>
